import React from 'react'
import loadable from '@loadable/component'
import { Styled } from 'theme-ui'
import ThemeUIPrism from '@theme-ui/prism'
import PrismCore from 'prismjs/components/prism-core'
import 'prismjs/components/prism-python'
import 'prismjs/components/prism-css'
import 'prismjs/components/prism-docker'
import 'prismjs/components/prism-json'
import 'prismjs/components/prism-sql'

// const Prism = loadable(() => import('@theme-ui/prism'))

const CodeBlock = props => (
  // eslint-disable-next-line react/jsx-pascal-case
  <ThemeUIPrism {...props} Prism={PrismCore} fallback={<Styled.pre>{props.children}</Styled.pre>} />
  // <Prism {...props} fallback={<Styled.pre>{props.children}</Styled.pre>} />
)

export default CodeBlock
